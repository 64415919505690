<template>
    <el-dialog :title="title" :close-on-click-modal="false" append-to-body :visible.sync="visible" width="55%">
        <el-menu :default-active="tabIndex" mode="horizontal" @select="handleSelectTab">
            <el-menu-item index="0">展览属性</el-menu-item>
            <el-menu-item index="1" v-if="hasPermission('sys:exhibition:link')">链接设置</el-menu-item>
            <el-menu-item index="2" v-if="hasPermission('sys:exhibition:card')">展览卡片字段显示设置</el-menu-item>
        </el-menu>
        <!--展览属性-->
        <div v-show="tabIndex == 0" class="m_t1">
            <el-form class="dialogForm" size="small" :model="inputForm" :rules="dataRule" v-loading="loading" :class="method==='view'?'readonly':''" :disabled="method==='view'" ref="inputForm" @keyup.enter.native="doSubmit()"
                     label-width="140px" @submit.native.prevent>
                <el-form-item label="展览编号：" prop="exhibitionId">
                    <el-input v-model.trim="inputForm.exhibitionId" maxlength="20" placeholder="请输入展览编号（例2020·01）"></el-input>
                </el-form-item>
                <el-form-item label="展览名称：" prop="exhibitionName">
                    <el-input v-model.trim="inputForm.exhibitionName" maxlength="200" placeholder="请输入展览名称"></el-input>
                </el-form-item>
                <el-form-item label="展览类型：" prop="exhibitionTypeValue">
                    <el-select v-model="inputForm.exhibitionTypeValue"  style="width:100%" placeholder="请选择">
                        <el-option
                                v-for="item in $dictUtils.getDictList('exhibition_type')"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="展览规模：" prop="exhibitionScaleValue">
                    <el-select v-model="inputForm.exhibitionScaleValue" style="width:100%" placeholder="请选择">
                        <el-option
                                v-for="item in $dictUtils.getDictList('exhibition_scale')"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="展览地区：" prop="exhibitionAreaValue">
                    <el-select v-model="inputForm.exhibitionAreaValue" style="width:100%" placeholder="请选择">
                        <el-option
                                v-for="item in $dictUtils.getDictList('exhibition_region')"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="展览地点：" prop="exhibitionPlaceValue">
                    <el-input v-model.trim="inputForm.exhibitionPlaceValue" maxlength="100"></el-input>

                    <!--                <el-select v-model="inputForm.exhibitionPlaceValue" style="width:100%" placeholder="请选择">-->
                    <!--                    <el-option-->
                    <!--                            v-for="item in $dictUtils.getDictList('exhibition_place')"-->
                    <!--                            :key="item.value"-->
                    <!--                            :label="item.label"-->
                    <!--                            :value="item.value">-->
                    <!--                    </el-option>-->
                    <!--                </el-select>-->
                </el-form-item>
                <el-form-item label="展览开始时间：" prop="beginDatetime">
                    <el-date-picker v-model="inputForm.beginDatetime" type="date"  placeholder="请选择日期"
                                    format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
                <el-form-item label="展览结束时间：" prop="endDatetime">
                    <el-date-picker v-model="inputForm.endDatetime"
                                    type="date" placeholder="请选择日期"  format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
                <el-form-item label="档案号：" prop="efieldName1">
                    <el-input v-model.trim="inputForm.efieldName1" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="保管期限：" prop="efieldName2">
                    <el-input v-model.trim="inputForm.efieldName2" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="存放位置：" prop="efieldName3">
                    <el-input v-model.trim="inputForm.efieldName3" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="立卷单位：" prop="efieldName4">
                    <el-input v-model.trim="inputForm.efieldName4" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="主办方：" prop="efieldName15">
                    <el-input v-model.trim="inputForm.efieldName15" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="总页数：" prop="efieldName5">
                    <el-input v-model.trim="inputForm.efieldName5" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="总件数：" prop="efieldName6">
                    <el-input v-model.trim="inputForm.efieldName6" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="总盒数：" prop="efieldName7">
                    <el-input v-model.trim="inputForm.efieldName7" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="起始盒号：" prop="efieldName8">
                    <el-input v-model.trim="inputForm.efieldName8" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="截止盒号：" prop="efieldName9">
                    <el-input v-model.trim="inputForm.efieldName9" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="密级：" prop="efieldName10">
                    <el-input v-model.trim="inputForm.efieldName10" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="策划部门：" prop="efieldName11">
                    <el-input v-model.trim="inputForm.efieldName11" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="档案馆号：" prop="efieldName12">
                    <el-input v-model.trim="inputForm.efieldName12" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="主题词：" prop="efieldName13">
                    <el-input v-model.trim="inputForm.efieldName13" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="档案馆代码：" prop="efieldName14">
                    <el-input v-model.trim="inputForm.efieldName14" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="展览主题：" prop="exhibitionTheme">
                    <el-input v-model.trim="inputForm.exhibitionTheme" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="展览年代：" prop="exhibitionAge">
                    <el-input v-model.trim="inputForm.exhibitionAge" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="展览主题类型：" prop="exhibitionThemeType">
                    <el-input v-model.trim="inputForm.exhibitionThemeType" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item label="展览主题人物：" prop="exhibitionSubject">
                    <el-input v-model.trim="inputForm.exhibitionSubject" maxlength="100"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <!--链接设置-->
        <div v-show="tabIndex == 1" class="m_t1">
            <el-form class="dialogForm" size="small" :model="inputForm" :disabled="method==='view'" ref="linkSetForm" label-width="120px">
                <el-row>
                    <el-col :span="24" v-for="(item, index) in inputForm.sysExhibitionLinks" :key="index">
                        <el-form-item :label="`链接${index+1}名称：`" :prop="`sysExhibitionLinks.${index}.linkName`"
                                     >
                            <el-col :span="16" class="m_r1">
                                <el-input v-model.trim="item.linkName" placeholder="请输入链接名称"></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item :label="`链接${index+1}URL：`" :prop="`sysExhibitionLinks.${index}.linkUrl`"
                                     >
                            <el-row>
                                <el-col :span="16" class="m_r1">
                                    <el-input v-model.trim="item.linkUrl" placeholder="请输入链接URL"></el-input>
                                </el-col>
                                <el-col :span="6">
                                    <el-button size="mini" type="primary" icon="el-icon-minus" @click="delOption(index)" v-if="inputForm.sysExhibitionLinks.length > 2"></el-button>
                                    <el-button size="mini" type="primary" icon="el-icon-plus" @click="addOption(index)"
                                               v-if="index+1 == inputForm.sysExhibitionLinks.length"></el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <!--展览卡片字段显示设置-->
        <div v-show="tabIndex == 2" class="m_t1">
            <div class="m_b1"><span class="f_w">首页显示设置</span>（首页显示最多设置9个）</div>
            <el-form size="small" :model="inputForm" class="m_b2" :disabled="method==='view'" ref="fieldForm" label-width="100px">
                <el-form-item label="首页显示：" prop="enable">
                    <el-switch v-model="inputForm.efieldName16" :active-value="0" :inactive-value="1" @change="switchChange"></el-switch>
                </el-form-item>
                <el-form-item label="排序号：" prop="sequence">
                    <el-input-number v-model="inputForm.efieldName17" :min="1" :max="9"></el-input-number>
                </el-form-item>
            </el-form>
            <div class="f_w m_b1">卡片字段设置</div>
            <el-row :gutter="20">
<!--                <el-col :span="16" v-if="method!='add'">-->
<!--                    <el-input  class="m_r1" size="small" maxlength="66" v-model="fieldSrh" placeholder="请输入搜索关键字" clearable style="width: 70%;"></el-input>-->
<!--                    <el-button type="primary" @click="searchField()" size="small" icon="el-icon-search">查询</el-button>-->
<!--                </el-col>-->
                <el-col :span="24" class="text_right">
                    <el-button @click="hideField()" size="small" :disabled="dataListSelections.length <= 0">隐藏</el-button>
                    <el-button type="primary" @click="showField()" size="small" :disabled="dataListSelections.length <= 0">显示</el-button>
                </el-col>
            </el-row>
            <el-table
                    :data="inputForm.sysExhibitionCards"
                    v-loading="loading"
                    @selection-change="selectionChangeHandle"
                    size="small"
                    height="calc(100vh - 520px)"
                    class="table m_t1">
                <el-table-column
                        type="selection"
                        header-align="center"
                        align="center"
                        width="50" :disabled="method == 'view'" v-if="method!='view'">
                </el-table-column>
                <el-table-column prop="exhibitionAttribute" label="展览属性"  show-overflow-tooltip></el-table-column>
                <el-table-column prop="exhibitionValue" label="值"  show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span  v-if="scope.row.exhibitionField=='exhibitionTypeValue'"> {{$dictUtils.getDictLabel("exhibition_type", scope.row.exhibitionValue, '-')}}</span>
                        <span v-else>{{scope.row.exhibitionValue}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="attributeFlag" label="是否启用">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.attributeFlag" :active-value="0" :inactive-value="1" :disabled="method==='view'"></el-switch>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button size="small" v-if="method != 'view'" type="primary" @click="doSubmit()" v-noMoreClick>保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data () {
            var checkEndTime = (rule, value, callback) => {
                if (!value) {
                    callback(new Error("请选择展览结束时间"));
                } else {
                    if (!this.inputForm.beginDatetime) {
                        callback(new Error("请选择展览开始时间！"))
                    } else if (Date.parse(this.inputForm.beginDatetime) >= Date.parse(value)) {
                        callback(new Error("展览结束时间必须大于展览开始时间！"))
                    } else {
                        callback();
                    }
                }
            }
            return {
                tabIndex: "0",
                visible: false,
                loading: false,
                title: '',
                method: '',
                inputForm: {
                    id: '',
                    exhibitionId: '',
                    exhibitionName: '',
                    exhibitionTypeValue: '',
                    exhibitionScaleValue: '',
                    exhibitionAreaValue: '',
                    exhibitionPlaceValue: '',
                    beginDatetime: '',
                    endDatetime: '',
                    exhibitionTheme: '',
                    exhibitionAge: '',
                    exhibitionThemeType: '',
                    exhibitionSubject: '',
                    efieldName1:"",
                    efieldName2:"",
                    efieldName3:"",
                    efieldName4:"",
                    efieldName5:"",
                    efieldName6:"",
                    efieldName7:"",
                    efieldName8:"",
                    efieldName9:"",
                    efieldName10:"",
                    efieldName11:"",
                    efieldName12:"",
                    efieldName13:"",
                    efieldName14:"",
                    efieldName15:"博物馆",
                    efieldName16:1,
                    efieldName17:1,
                    exhibitionUserName:"",
                    sysExhibitionLinks: [
                        {
                            linkName: '展览网页',
                            linkUrl: '',
                        },
                        {
                            linkName: '数字展厅',
                            linkUrl: '',
                        }
                    ],
                    sysExhibitionCards: [
                        {
                            exhibitionAttribute:"展览编号",
                            exhibitionField:"exhibitionId",
                            exhibitionValue:"",
                            attributeFlag:0,
                        },
                        {
                            exhibitionAttribute:"展览名称",
                            exhibitionField:"exhibitionName",
                            exhibitionValue:"",
                            attributeFlag:0,
                        },
                        {
                            exhibitionAttribute:"主题词",
                            exhibitionField:"efieldName13",
                            exhibitionValue:"",
                            attributeFlag:0,
                        },
                        {
                            exhibitionAttribute:"主办方",
                            exhibitionField:"efieldName15",
                            exhibitionValue:"",
                            attributeFlag:0,
                        },
                        {
                            exhibitionAttribute:"策展人",
                            exhibitionField:"exhibitionUserName",
                            exhibitionValue:"",
                            attributeFlag:0,
                        },
                        {
                            exhibitionAttribute:"展览类型",
                            exhibitionField:"exhibitionTypeValue",
                            exhibitionValue:"",
                            attributeFlag:0,
                        },
                        {
                            exhibitionAttribute:"展览开始时间",
                            exhibitionField:"beginDatetime",
                            exhibitionValue:"",
                            attributeFlag:0,
                        },
                        {
                            exhibitionAttribute:"展览结束时间",
                            exhibitionField:"endDatetime",
                            exhibitionValue:"",
                            attributeFlag:0,
                        },
                    ],
                },
                dataRule: {
                    exhibitionId: [{required: true, message: '展览编号不能为空', trigger: 'blur'}],
                    exhibitionName: [{required: true, message: '展览名称不能为空', trigger: 'blur'}],
                    exhibitionTypeValue: [{required: true, message: '展览类型不能为空', trigger: 'change'}],
                    exhibitionScaleValue: [{required: true, message: '展览规模不能为空', trigger: 'change'}],
                    exhibitionAreaValue: [{required: true, message: '展览地区不能为空', trigger: 'change'}],
                    exhibitionPlaceValue: [{required: true, message: '展览地点不能为空', trigger: 'change'}],
                    beginDatetime: [{required: true, message: '展览开始时间不能为空', trigger: 'change'}],
                    endDatetime: [{required: true, message: '展览结束时间不能为空', trigger: 'change'},{ validator: checkEndTime, trigger: 'change' }],
                    efieldName1: [{required: true, message: '档案号不能为空', trigger: 'blur'}],
                    efieldName2: [{required: true, message: '保管期限不能为空', trigger: 'blur'}],
                    efieldName3: [{required: true, message: '存放位置不能为空', trigger: 'change'}],
                    efieldName4: [{required: true, message: '立卷单位不能为空', trigger: 'change'}],
                    efieldName15: [{required: true, message: '主办方不能为空', trigger: 'change'}],
                    linkName: [{required: true, message: '链接名称不能为空', trigger: 'blur'}],
                    linkUrl: [{required: true, message: '链接地址不能为空', trigger: 'blur'}],

                },
                fieldSrh: '',
                dataListSelections: [],
            }
        },
        watch: {


        },
        methods: {
            switchChange(){
                if(this.inputForm.efieldName16==0){
                    this.$axios(this.api.infor.queryShowCount,{}, 'get').then(data => {
                        if (data && data.status) {
                            if(data.data>8){
                                this.inputForm.efieldName16 = 1
                                this.$message.error("首页显示最大显示9个，请关闭其他展览首页再重试！")
                            }else{
                                this.inputForm.efieldName17=parseInt(data.data)+1
                            }
                        }
                    })
                }
            },
            init (method, row) {
                this.method = method
                this.tabIndex='0'
                this.inputForm.sysExhibitionLinks = [
                    {
                        linkName: '展览网页',
                        linkUrl: '',
                    },
                    {
                        linkName: '数字展厅',
                        linkUrl: '',
                    }
                ]
                this.inputForm.sysExhibitionCards = [
                    {
                        exhibitionAttribute:"展览编号",
                        exhibitionField:"exhibitionId",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        exhibitionAttribute:"展览名称",
                        exhibitionField:"exhibitionName",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        exhibitionAttribute:"主题词",
                        exhibitionField:"efieldName13",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        exhibitionAttribute:"主办方",
                        exhibitionField:"efieldName15",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        exhibitionAttribute:"策展人",
                        exhibitionField:"exhibitionUserName",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        exhibitionAttribute:"展览类型",
                        exhibitionField:"exhibitionTypeValue",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        exhibitionAttribute:"展览开始时间",
                        exhibitionField:"beginDatetime",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        exhibitionAttribute:"展览结束时间",
                        exhibitionField:"endDatetime",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                ]
                if (method === 'add') {
                    this.title = `新建展览`
                    this.inputForm.id = ''
                    this.inputForm.efieldName15= '博物馆'
                    this.inputForm.efieldName16= 1
                    this.inputForm.efieldName17= 1

                } else if (method === 'edit') {
                    this.title = '编辑展览'
                    this.inputForm.id = row.id
                    this.loading = true
                } else if (method === 'view') {
                    this.title = '查看展览'
                    this.inputForm.id = row.id
                    this.loading = true
                }
                this.visible = true
                let that = this;
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    if (method === 'edit' || method === 'view') { // 修改或者查看
                        this.$axios(this.api.infor.informationQuery,{id:this.inputForm.id}, 'get').then(data => {
                            if (data && data.status) {
                                this.loading = false
                                let sysExhibitionLinks= JSON.parse(JSON.stringify(this.inputForm.sysExhibitionLinks))
                                let sysExhibitionCards= JSON.parse(JSON.stringify(this.inputForm.sysExhibitionCards))
                                this.inputForm = this.recover(this.inputForm, data.data)
                                this.$set(this.inputForm ,"sysExhibitionLinks",sysExhibitionLinks)
                                this.$set(this.inputForm ,"sysExhibitionCards",sysExhibitionCards)

                                // console.log( this.inputForm);
                                if(this.hasPermission('sys:exhibition:link')){
                                    this.$axios(this.api.infor.SysExhibitionLinkList,{id:this.inputForm.id}, 'get').then(data => {
                                        if (data.data.length>0 && data.status) {
                                            // this.$set(this.inputForm ,"sysExhibitionLinks",data.data)
                                            this.inputForm.sysExhibitionLinks =  data.data
                                        }
                                    })
                                }
                                if(this.hasPermission('sys:exhibition:card')){
                                    this.$axios(this.api.infor.SysExhibitionCardList,{id:this.inputForm.id}, 'get').then(data => {
                                        if (data.data.length>0 && data.status) {
                                            // this.$set(this.inputForm ,"sysExhibitionLinks",data.data)
                                            this.inputForm.sysExhibitionCards =  data.data
                                            this.inputForm.sysExhibitionCards.forEach(function (v) {
                                                if(v.exhibitionField=='exhibitionUserName'){
                                                    v.exhibitionValue = that.inputForm.exhibitionUserName
                                                }
                                            })

                                        }else {
                                            this.card()
                                        }
                                    })
                                }
                            }
                        })
                    }
                })
            },
            // tab切换
            handleSelectTab(index) {
                this.tabIndex = index;
            },
            // 卡片字段搜索
            searchField() {

            },
            // 展览卡片字段显示设置数据查询
            refreshList() {

            },
            // 是否启用
            up(row){
                this.$axios(this.api.report.aaa, {
                    id:row.id,
                    enable:row.enable,
                }, 'put').then(data => {
                    if (data && data.status) {
                        this.refreshList();
                    }
                })
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 显示
            showField() {
                this.dataListSelections.forEach(function (v) {
                    v.attributeFlag = 0
                })
            },
            // 隐藏
            hideField() {
                this.dataListSelections.forEach(function (v) {
                    v.attributeFlag = 1
                })
            },
            // 表单提交
            doSubmit () {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.save()
                    }
                })
            },
            card(){
                let that = this;
                this.inputForm.sysExhibitionCards.forEach(function (v) {
                    if(v.exhibitionField=='exhibitionUserName'){
                        v.exhibitionValue = that.inputForm.exhibitionUserName
                    }
                    for (let key of Object.keys(that.inputForm)) {
                        if(v.exhibitionField == key){
                            v.exhibitionValue = that.inputForm[key];
                            // console.log(v.exhibitionValue);
                            // if(v.exhibitionField == 'exhibitionTypeValue'){
                            // }
                        }
                    }
                })
            },
            save(){
                this.loading = true
                // console.log(this.inputForm);
                this.inputForm.beginDatetime = this.$publicUtile.formatDate(this.inputForm.beginDatetime,1)
                this.inputForm.endDatetime = this.$publicUtile.formatDate(this.inputForm.endDatetime,3)
                // console.log(this.inputForm);
                if(this.title==`新建展览`){
                    if(!this.hasPermission('sys:exhibition:link')){
                        this.inputForm.sysExhibitionLinks = [];
                    }
                    if(this.hasPermission('sys:exhibition:card')){
                        this.card();
                    }else{
                        this.inputForm.sysExhibitionCards = [];
                    }
                    this.$axios(this.api.infor.informationAdd, this.inputForm, 'post').then(data => {
                        this.loading = false
                        if (data && data.status) {
                            this.$message.success(data.msg)
                            this.visible = false
                            this.$emit('refreshDataList')
                        }else{
                            this.$message.error(data.msg)
                        }
                    })
                }else{
                    if(!this.hasPermission('sys:exhibition:link')){
                        this.inputForm.sysExhibitionLinks = [];
                    }
                    if(!this.hasPermission('sys:exhibition:card')){
                        this.inputForm.sysExhibitionCards = [];
                    }else{
                        this.card();
                    }
                    this.$axios(this.api.infor.informationEdit, this.inputForm, 'put').then(data => {
                        this.loading = false
                        if (data && data.status) {
                            this.$message.success(data.msg)
                            this.visible = false
                            this.$emit('refreshDataList')
                        }
                    })
                }
            },
            // 添加
            addOption(index) {
                this.inputForm.sysExhibitionLinks.push(
                    {
                        link1Name: '',
                        link1Url: '',
                    }
                )
            },
            // 删除
            delOption(index) {
                this.inputForm.sysExhibitionLinks.splice(index, 1);    //删除数组中的该条数据
            },
        }
    }
</script>
